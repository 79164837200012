
.vue-modal-top,
.vue-modal-bottom,
.vue-modal-left,
.vue-modal-right,
.vue-modal-topRight,
.vue-modal-topLeft,
.vue-modal-bottomLeft,
.vue-modal-bottomRight {
  display: block;
  overflow: hidden;
  position: absolute;
  background: transparent;
  z-index: 9999999;
}
.vue-modal-topRight,
.vue-modal-topLeft,
.vue-modal-bottomLeft,
.vue-modal-bottomRight {
  width: 12px;
  height: 12px;
}
.vue-modal-top {
  right: 12;
  top: 0;
  width: 100%;
  height: 12px;
  cursor: n-resize;
}
.vue-modal-bottom {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 12px;
  cursor: s-resize;
}
.vue-modal-left {
  left: 0;
  top: 0;
  width: 12px;
  height: 100%;
  cursor: w-resize;
}
.vue-modal-right {
  right: 0;
  top: 0;
  width: 12px;
  height: 100%;
  cursor: e-resize;
}
.vue-modal-topRight {
  right: 0;
  top: 0;
  background: transparent;
  cursor: ne-resize;
}
.vue-modal-topLeft {
  left: 0;
  top: 0;
  cursor: nw-resize;
}
.vue-modal-bottomLeft {
  left: 0;
  bottom: 0;
  cursor: sw-resize;
}
.vue-modal-bottomRight {
  right: 0;
  bottom: 0;
  cursor: se-resize;
}
#vue-modal-triangle::after {
  display: block;
  position: absolute;
  content: '';
  background: transparent;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-bottom: 10px solid #ddd;
  border-left: 10px solid transparent;
}
#vue-modal-triangle.clicked::after {
  border-bottom: 10px solid #369be9;
}


.vm--block-scroll {
  overflow: hidden;
  width: 100vw;
}
.vm--container {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
}
.vm--overlay {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  /* z-index: 999; */
  opacity: 1;
}
.vm--container.scrollable {
  height: 100%;
  min-height: 100vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.vm--modal {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  background-color: white;
  border-radius: 3px;
  box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
}
.vm--container.scrollable .vm--modal {
  margin-bottom: 2px;
}
.vm--top-right-slot {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
.vm-transition--overlay-enter-active,
.vm-transition--overlay-leave-active {
  transition: all 50ms;
}
.vm-transition--overlay-enter,
.vm-transition--overlay-leave-active {
  opacity: 0;
}
.vm-transition--modal-enter-active,
.vm-transition--modal-leave-active {
  transition: all 400ms;
}
.vm-transition--modal-enter,
.vm-transition--modal-leave-active {
  opacity: 0;
  transform: translateY(-20px);
}
.vm-transition--default-enter-active,
.vm-transition--default-leave-active {
  transition: all 2ms;
}
.vm-transition--default-enter,
.vm-transition--default-leave-active {
  opacity: 0;
}


.vue-dialog {
  font-size: 14px;
}
.vue-dialog div {
  box-sizing: border-box;
}
.vue-dialog-content {
  flex: 1 0 auto;
  width: 100%;
  padding: 14px;
}
.vue-dialog-content-title {
  font-weight: 600;
  padding-bottom: 14px;
}
.vue-dialog-buttons {
  display: flex;
  flex: 0 1 auto;
  width: 100%;
  border-top: 1px solid #eee;
}
.vue-dialog-buttons-none {
  width: 100%;
  padding-bottom: 14px;
}
.vue-dialog-button {
  font-size: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 40px;
  height: 40px;
  color: inherit;
  font: inherit;
  outline: none;
}
.vue-dialog-button:hover {
  background: #f9f9f9;
}
.vue-dialog-button:active {
  background: #f3f3f3;
}
.vue-dialog-button:not(:first-of-type) {
  border-left: 1px solid #eee;
}

